import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
export var constantRoutes = [{
  path: '',
  component: Layout,
  redirect: '/dashboard'
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true,
  meta: {
    auth: false
  }
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true,
  meta: {
    auth: false
  }
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true,
  meta: {
    auth: false
  }
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true,
  meta: {
    auth: false
  }
}, {
  path: '/documentation',
  component: Layout,
  meta: {
    roles: ['developer']
  },
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/documentation/index');
    },
    name: 'Documentation',
    meta: {
      title: 'documentation',
      icon: 'documentation',
      affix: false
    }
  }]
}, {
  path: '/guide',
  component: Layout,
  redirect: '/guide/index',
  meta: {
    roles: ['developer']
  },
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/guide/index');
    },
    name: 'Guide',
    meta: {
      title: 'guide',
      icon: 'guide',
      noCache: true
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  meta: {
    roles: ['developer']
  },
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/profile/index');
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}, {
  path: '/dashboard',
  component: Layout,
  name: 'Dashboard',
  meta: {
    title: 'dashboard',
    icon: 'dashboard',
    permission: ['dashboard']
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      noCache: true,
      affix: true
    }
  }]
}];
var learnRouters = [{
  path: '/permission',
  component: Layout,
  redirect: '/permission/page',
  alwaysShow: true,
  // will always show the root menu
  name: 'Permission',
  meta: {
    title: 'permission',
    icon: 'lock',
    roles: ['developer'] // you can set roles in root nav
  },
  children: [{
    path: 'page',
    component: function component() {
      return import('@/views/permission/page');
    },
    name: 'PagePermission',
    meta: {
      title: 'pagePermission',
      roles: ['admin'] // or you can only set roles in sub nav
    }
  }, {
    path: 'directive',
    component: function component() {
      return import('@/views/permission/directive');
    },
    name: 'DirectivePermission',
    meta: {
      title: 'directivePermission'
    }
  }]
}, {
  path: '/icon',
  component: Layout,
  meta: {},
  hidden: false,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/icons/index');
    },
    name: 'Icons',
    meta: {
      title: 'icons',
      icon: 'icon',
      noCache: true
    }
  }]
}];
export var asyncRoutes = [
// 综合调度
{
  path: '/monitorDispatch',
  component: Layout,
  meta: {
    permissions: ['monitoring'],
    title: 'MonitoringDispatching',
    icon: 'form'
  },
  children: [{
    path: 'PlaceOrder',
    component: function component() {
      return import('@/views/placeOrder/index');
    },
    name: 'PlaceOrder',
    meta: {
      title: 'placeOrder',
      icon: 'dispatch',
      affix: true,
      affixWhenPermissionIs: 'monitoring.map',
      permissions: ['monitoring.map']
    }
  }, {
    path: 'information-delivery',
    component: function component() {
      return import('@/views/information-delivery/index');
    },
    name: 'information-delivery',
    meta: {
      title: 'informationDelivery',
      icon: 'rent_release',
      affix: false,
      permissions: ['article.post:view']
    }
  }, {
    path: 'alarm',
    component: function component() {
      return import('@/views/alarm/index');
    },
    name: 'alarmX',
    meta: {
      title: 'alarm',
      icon: 'alarm',
      affix: false,
      permissions: ['monitoring.alarm']
    }
  }, {
    path: 'complaint',
    component: function component() {
      return import('@/views/complaint/index');
    },
    name: 'complaint',
    meta: {
      title: 'complaint',
      icon: 'complaint',
      affix: false,
      permissions: ['monitoring.complaint']
    }
  }]
},
// 订单列表
{
  path: '/order',
  component: Layout,
  meta: {
    permissions: ['order'],
    icon: 'form',
    affix: false,
    title: 'orderManagement'
  },
  children: [{
    path: 'orderList',
    component: function component() {
      return import('@/views/orderList/index');
    },
    name: 'OrderList',
    meta: {
      title: 'orderList',
      icon: 'form',
      affix: true,
      permissions: ['order.list']
    }
  }]
},
// 失物查找
{
  path: '/lost',
  component: Layout,
  meta: {
    permissions: ['lost']
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/lost/index');
    },
    name: 'Lost',
    meta: {
      title: 'lost',
      icon: '404',
      affix: false,
      permissions: ['lost']
    }
  }]
},
// 车辆监控
{
  path: '/vehicle-monitor',
  component: Layout,
  meta: {
    permissions: ['vehiclemonitor'],
    icon: 'car_monitor',
    affix: false,
    title: 'vehicleMonitorTitle'
  },
  children: [{
    path: 'vehicleMonitoring',
    component: function component() {
      return import('@/views/vehicleMonitoring/index');
    },
    name: 'vehicleMonitoring',
    meta: {
      title: 'vehicleMonitorMap',
      icon: 'map_monitor',
      affix: false,
      permissions: ['vehiclemonitor.map']
    }
  }, {
    path: 'outCityMonitoring',
    component: function component() {
      return import('@/views/outCityMonitoring/index');
    },
    name: 'outCityMonitoring',
    meta: {
      title: '车辆出城监控',
      icon: 'map_monitor',
      affix: false,
      permissions: ['vehiclemonitor.outCity']
    }
  }, {
    path: 'realTimeInformation',
    component: function component() {
      return import('@/views/realTimeInformation/index');
    },
    name: 'realTimeInformation',
    meta: {
      title: 'vehicleMonitorRealMsg',
      icon: 'real_data',
      affix: false,
      permissions: ['vehiclemonitor.realtime']
    }
  }, {
    path: 'vehicle-taxi',
    component: function component() {
      return import('@/layout/second');
    },
    name: 'second',
    meta: {
      title: 'vehicleTaxi',
      icon: 'vehicle_taxi',
      affix: false,
      permissions: ['vehiclemonitor.nntaxi']
    },
    children: [{
      path: 'realTimeMonitor',
      component: function component() {
        return import('@/views/nanNingTaxi/realTimeMonitor/index');
      },
      name: 'realTimeMonitor',
      meta: {
        title: '实时监控',
        icon: 'ssjk',
        affix: false,
        permissions: ['vehiclemonitor.nntaxi.realtimemonitor']
      }
    }, {
      path: 'trackReplay',
      component: function component() {
        return import('@/views/nanNingTaxi/trackReplay/index');
      },
      name: 'trackReplay',
      meta: {
        title: '轨迹回放',
        icon: 'gjhf',
        affix: false,
        permissions: ['vehiclemonitor.nntaxi.trajectory']
      }
    }, {
      path: 'taxi-monitor',
      component: function component() {
        return import('@/views/taximonitor/index');
      },
      name: 'TaxiMonitor',
      meta: {
        title: 'taxiMonitor',
        icon: 'vehicle_taxi',
        affix: false,
        permissions: ['vehiclemonitor.nntaximonitor']
      }
    }, {
      path: 'monitorAddress',
      component: function component() {
        return import('@/views/monitorAddress/index');
      },
      name: 'monitorAddress',
      meta: {
        title: 'taxiPositionDealTool',
        icon: 'map',
        affix: false,
        permissions: ['vehiclemonitor.nntaxiPositionDealTool']
      }
    }, {
      path: 'dataCompare',
      component: function component() {
        return import('@/views/dataCompare/index');
      },
      name: 'dataCompare',
      meta: {
        title: 'taxiOutCityCompare',
        icon: 'car_compare',
        affix: false,
        permissions: ['vehiclemonitor.nntaxiOutCityCompare']
      }
    }]
  }]
},
// 车辆管理
{
  path: '/vehicle-manage',
  component: Layout,
  meta: {
    permissions: ['vehiclemgr'],
    icon: 'form',
    affix: false,
    title: 'vehicleManage'
  },
  children: [{
    path: 'vehicle-info',
    component: function component() {
      return import('@/views/vehicleInformation/index');
    },
    name: 'vehicleInformation',
    meta: {
      title: 'vehicleManageMsg',
      icon: 'driver_data',
      affix: false,
      permissions: ['vehiclemgr.info']
    }
  }, {
    path: 'vehicle-taxi',
    component: function component() {
      return import('@/layout/second');
    },
    name: 'second',
    meta: {
      title: 'vehicleTaxi',
      icon: 'vehicle_taxi',
      affix: false,
      permissions: ['vehiclemgr.nntaxi']
    },
    children: [{
      path: 'vehicle-taxi-list',
      component: function component() {
        return import('@/views/vehicle-management/info/VehicleInfoIndex');
      },
      name: 'VehicleInformation',
      meta: {
        title: 'vehicleTaxiList',
        icon: 'taxi',
        affix: false,
        permissions: ['vehiclemgr.nntaxilist']
      }
    }, {
      path: 'vehicle-taxi-light',
      component: function component() {
        return import('@/views/light/lightIndex');
      },
      name: 'lightIndex',
      meta: {
        title: 'vehicleTaxiLight',
        icon: 'brush',
        affix: false,
        permissions: ['vehiclemgr.nntaxitoplight']
      }
    }]
  }]
},
// 司机管理
{
  path: '/driver-manage',
  component: Layout,
  meta: {
    permissions: ['drivermgr'],
    icon: 'driver_manager',
    affix: false,
    title: 'driverManage'
  },
  children: [{
    path: 'driver-info',
    component: function component() {
      return import('@/views/driverInformation/index');
    },
    name: 'DriverInformation',
    meta: {
      title: 'driverManageInfo',
      icon: 'form',
      affix: false,
      permissions: ['drivermgr.info']
    }
  }, {
    path: 'driver-index',
    component: function component() {
      return import('@/views/driverManagement/driver-index');
    },
    name: 'DriverIndex',
    meta: {
      title: 'driverManageAccount',
      icon: 'account',
      affix: false,
      permissions: ['drivermgr.account']
    }
  }, {
    path: 'driver-approval',
    component: function component() {
      return import('@/views/driverManagement/driver-approval');
    },
    name: 'DriverApproval',
    meta: {
      title: 'driverAccountApproval',
      icon: 'account',
      affix: false,
      permissions: ['drivermgr.account']
    }
  }, {
    path: 'driver-monitor',
    component: function component() {
      return import('@/views/monitor/index');
    },
    name: 'MonitorX',
    meta: {
      title: 'driverManageMonitor',
      icon: 'form',
      affix: false,
      permissions: ['drivermgr.monitor']
    }
  }, {
    path: 'driver-check',
    component: function component() {
      return import('@/views/checkdrivers/index');
    },
    name: 'checkIndex',
    meta: {
      title: 'driverCheck',
      icon: 'spot_check',
      affix: false,
      permissions: ['drivermgr.check']
    }
  }]
},
//签署协议管理
{
  path: '/agreementMange',
  component: Layout,
  meta: {
    permissions: ['agreement'],
    title: '签署协议管理',
    icon: 'agreement'
  },
  children: [{
    path: 'agreementList',
    component: function component() {
      return import('@/views/agreementMange/agreementList');
    },
    name: 'agreementList',
    meta: {
      title: '签署协议管理',
      icon: 'agreement',
      affix: false,
      permissions: ['agreement.sign']
    }
  }]
},
// {
//   path: '/driver-home',
//   component: Layout,
//   meta: { permissions: ['driverhome'], icon: 'driver_manager', affix: false, title: 'driverHome' },
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/driver-home/order/index'),
//       name: 'driverHomeIndex',
//       meta: { title: 'driverHomeOrder', icon: 'shopping', affix: false, permissions: ['driverHome'] }
//     }
//   ]
// },
// 广告管理
{
  path: '/advertisementIndex',
  component: Layout,
  meta: {
    permissions: ['adPastePictureMange'],
    title: 'advertisementManage',
    icon: 'form'
  },
  children: [{
    path: 'advertisementIndex',
    props: true,
    component: function component() {
      return import('@/views/advertisement/AdvertisementIndex');
    },
    name: 'AdvertisementIndex',
    meta: {
      title: 'advertisementList',
      icon: 'brush',
      affix: false,
      permissions: ['adPastePictureMange']
    }
  }, {
    path: 'projectList',
    component: function component() {
      return import('@/views/advertisement/ProjectList');
    },
    name: 'ProjectList',
    meta: {
      title: 'projectList',
      icon: 'project',
      affix: false,
      permissions: ['adInstallment']
    }
  }, {
    path: 'customerList',
    component: function component() {
      return import('@/views/advertisement/CustomerList');
    },
    name: 'CustomerList',
    meta: {
      title: 'customerList',
      icon: 'people',
      affix: false,
      permissions: ['adCoustomer']
    }
  }, {
    path: 'rewardList',
    component: function component() {
      return import('@/views/advertisement/RewardList');
    },
    name: 'RewardList',
    meta: {
      title: 'rewardList',
      icon: 'gift',
      affix: false,
      permissions: ['adDriverGift']
    }
  }, {
    path: 'vehicleRemark',
    component: function component() {
      return import('@/views/advertisement/remark/VehicleRemarkList');
    },
    name: 'VehicleRemarkList',
    meta: {
      title: 'vehicleRemarkApproval',
      icon: 'approval',
      affix: false,
      permissions: ['adPastePictureLog']
    }
  }, {
    path: 'stats',
    component: function component() {
      return import('@/views/dataManagement/index');
    },
    name: 'dataManagement',
    meta: {
      title: 'data',
      icon: 'form',
      affix: false,
      permissions: ['adPastePictureStats']
    }
  }, {
    path: 'hammerapp',
    component: function component() {
      return import('@/views/advertisement/HammerAppFrame');
    },
    name: 'HammerAppFrame',
    meta: {
      title: 'hammerapp',
      icon: 'money',
      affix: false,
      permissions: ['hammerInfo']
    }
  }, {
    path: 'pictureHistory',
    component: function component() {
      return import('@/views/advertisement/pictureHistory');
    },
    name: 'pictureHistory',
    meta: {
      title: 'pictureHistory',
      icon: 'money',
      affix: false,
      permissions: ['pictureExportHistory']
    }
  }, {
    path: 'vehicle',
    component: function component() {
      return import('@/views/advertisement/vehicle/index');
    },
    name: 'vehicleX',
    meta: {
      title: 'vehicle',
      icon: 'approval',
      affix: false,
      permissions: ['adPasteDriverMange']
    }
  }, {
    path: 'driverRewards',
    component: function component() {
      return import('@/views/advertisement/driverRewards');
    },
    name: 'driverRewards',
    meta: {
      title: 'driverRewards',
      icon: 'approval',
      affix: false,
      permissions: ['AdDriverReward']
    }
  }, {
    path: 'outlay-statistic',
    component: function component() {
      return import('@/views/outlayStatistic');
    },
    name: 'outlayStatistic',
    meta: {
      title: 'outlayStatistic',
      icon: 'statistics',
      affix: false,
      permissions: ['AdBudgetTotal']
    }
  }]
},
//活动管理
{
  path: '/activity',
  component: Layout,
  meta: {
    permissions: ['driverhome.activity'],
    title: 'activityManage',
    icon: 'hdgl'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/activityManage/activityIndex');
    },
    name: 'activityIndex',
    meta: {
      title: '活动列表',
      icon: 'hdgl',
      affix: false,
      permissions: ['driverhome.activitylist']
    }
  }, {
    path: 'team',
    component: function component() {
      return import('@/views/activityManage/team');
    },
    name: 'teamManage',
    meta: {
      title: '队伍管理',
      icon: 'dwgl',
      affix: false,
      permissions: ['driverhome.teamlist']
    }
  }, {
    path: 'rank',
    component: function component() {
      return import('@/views/activityManage/rank');
    },
    name: 'rankManage',
    meta: {
      title: '排行榜管理',
      icon: 'phbgl',
      affix: false,
      permissions: ['driverhome.rankinglist']
    }
  }, {
    path: 'reward',
    component: function component() {
      return import('@/views/activityManage/reward');
    },
    name: 'rewardManage',
    meta: {
      title: '奖励管理',
      icon: 'jlgl',
      affix: false,
      permissions: ['driverhome.rankinglist']
    }
  }]
},
// 积分商城
{
  path: '/scoreShop',
  component: Layout,
  meta: {
    permissions: ['driverhome.scorestore'],
    title: 'scoreShop',
    icon: 'jfsc'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/scoreShop/shopManage');
    },
    name: 'shopManage',
    meta: {
      title: '商品管理',
      icon: 'spgl',
      affix: false,
      permissions: ['driverhome.productlist']
    }
  }, {
    path: 'selfPickupPointManage',
    component: function component() {
      return import('@/views/scoreShop/selfPickupPointManage');
    },
    name: 'selfPickupPointManage',
    meta: {
      title: '自提点管理',
      icon: 'ztdgl',
      affix: false,
      permissions: ['driverhome.pickuplist']
    }
  }, {
    path: 'inventoryManage',
    component: function component() {
      return import('@/views/scoreShop/inventoryManage');
    },
    name: 'inventoryManage',
    meta: {
      title: '入库管理',
      icon: 'ruku',
      affix: false,
      permissions: ['driverhome.pickuplist']
    }
  }, {
    path: 'customerServiceManage',
    component: function component() {
      return import('@/views/scoreShop/customerServiceManage');
    },
    name: 'customerServiceManage',
    meta: {
      title: '客服管理',
      icon: 'kefu',
      affix: false,
      permissions: ['driverhome.pickuplist']
    }
  }, {
    path: 'orderManage',
    component: function component() {
      return import('@/views/scoreShop/orderManage');
    },
    name: 'orderManage',
    meta: {
      title: '订单管理',
      icon: 'ddgl',
      affix: false,
      permissions: ['driverhome.orderlist']
    }
  }, {
    path: 'scoreManage',
    component: function component() {
      return import('@/views/scoreShop/scoreManage');
    },
    name: 'scoreManage',
    meta: {
      title: '积分管理',
      icon: 'jfgl',
      affix: false,
      permissions: ['driverhome.scorelist']
    }
  }, {
    path: 'writeOffManage',
    component: function component() {
      return import('@/views/scoreShop/writeOffManage');
    },
    name: 'writeOffManage',
    meta: {
      title: '核销管理',
      icon: 'hxgl',
      affix: false,
      permissions: ['driverhome.takelist']
    }
  }]
},
// 租车管理
{
  path: '/rentalCar',
  component: Layout,
  meta: {
    permissions: ['driverhome.lease'],
    title: '租/卖车管理',
    icon: 'zcgl'
  },
  children: [{
    path: 'rentalCarInfo',
    component: function component() {
      return import('@/views/rentalCar/rentalCarInfo');
    },
    name: 'rentalCarInfo',
    meta: {
      title: '车辆信息',
      icon: 'zcgl',
      affix: false,
      permissions: ['driverhome.lease.info']
    }
  }, {
    path: 'companyManage',
    component: function component() {
      return import('@/views/rentalCar/companyManage');
    },
    name: 'companyManage',
    meta: {
      title: '车源公司管理',
      icon: 'sjzxxx',
      affix: false,
      permissions: ['driverhome.lease.info']
    }
  }, {
    path: 'consultInfo',
    component: function component() {
      return import('@/views/rentalCar/consultInfo');
    },
    name: 'consultInfo',
    meta: {
      title: '司机咨询信息',
      icon: 'sjzxxx',
      affix: false,
      permissions: ['driverhome.lease.driverconsult']
    }
  }]
},
// 财务管理
{
  path: '/finance',
  component: Layout,
  meta: {
    title: 'financeManagement',
    icon: 'money',
    permissions: ['finance']
  },
  children: [{
    path: 'income',
    component: function component() {
      return import('@/views/finance/income/index');
    },
    name: 'Income',
    meta: {
      title: 'income',
      icon: 'money',
      permissions: ['finance.income']
    }
  }, {
    path: 'outlay',
    component: function component() {
      return import('@/views/finance/outlay/index');
    },
    name: 'Outlay',
    meta: {
      title: 'outlay',
      icon: 'guide',
      permissions: ['finance.outlay']
    }
  }, {
    hidden: false,
    path: 'lost',
    component: function component() {
      return import('@/views/finance/lost/index');
    },
    name: 'FinanceLostIndex',
    meta: {
      title: 'lost',
      icon: 'money',
      permissions: ['finance.income']
    }
  }, {
    path: 'cashIndex',
    component: function component() {
      return import('@/views/cash/index');
    },
    name: 'cashIndexX',
    meta: {
      title: 'cash',
      icon: 'form',
      affix: false,
      permissions: ['finance.withdrawal']
    }
  }]
},
// 报表统计
{
  path: '/report-statistics',
  component: Layout,
  meta: {
    permissions: ['reportStatistics'],
    icon: 'statistics',
    affix: false,
    title: 'reportStatistics'
  },
  children: [{
    path: 'statistics-order',
    component: function component() {
      return import('@/views/order/index');
    },
    name: 'OrderX',
    meta: {
      title: 'orderStatistics',
      icon: 'statistics',
      affix: false,
      permissions: ['reportStatistics.order']
    }
  }, {
    path: 'city-statistic',
    component: function component() {
      return import('@/views/citys-statistics/index');
    },
    name: 'cityStatistic',
    meta: {
      title: 'citiesStatistic',
      icon: 'car_statistics',
      affix: false,
      permissions: ['reportStatistics.city']
    }
  }, {
    path: 'call-statistics',
    component: function component() {
      return import('@/views/callStatistics');
    },
    name: 'CallStatistics',
    meta: {
      title: 'callStatistics',
      icon: 'statistics',
      affix: false,
      permissions: ['reportStatistics.call']
    }
  }]
},
// 系统管理
{
  path: '/system-manage',
  component: Layout,
  meta: {
    permissions: ['systemmgr'],
    title: 'systemManage',
    icon: 'system'
  },
  children: [{
    path: 'admin-user',
    component: function component() {
      return import('@/views/adminuser/index');
    },
    name: 'adminUser',
    meta: {
      title: 'userIndex',
      icon: 'system',
      affix: false,
      permissions: ['usermgr.list']
    }
  },
  // {
  //   path: 'security',
  //   component: () => import('@/views/system/security-config/index'),
  //   name: 'SecurityConfig',
  //   meta: { title: 'securityConfig', icon: 'people', affix: false, permissions: ['systemmgr.security'] }
  // },

  // {
  //   path: 'oauth',
  //   component: () => import('@/views/oauth/index'),
  //   name: 'oauth',
  //   meta: { title: 'oauth', icon: 'form', affix: false, permissions: ['oauth.list'] }
  // },
  // {
  //   path: 'login-log',
  //   component: () => import('@/views/login-log/index'),
  //   name: 'login-log',
  //   meta: { title: 'loginLogManage', icon: 'form', affix: false, permissions: ['systemmgr.loginLog'] }
  // },
  {
    path: 'operate-log',
    component: function component() {
      return import('@/views/operateLog/index.vue');
    },
    name: 'operate-log',
    meta: {
      title: 'operateLog',
      icon: 'form',
      affix: false,
      permissions: ['systemmgr.operatelog']
    }
  }, {
    path: 'system-config',
    component: function component() {
      return import('@/views/system/system-config/index');
    },
    name: 'system-config',
    meta: {
      title: 'systemConfig',
      icon: 'system',
      affix: false,
      permissions: ['systemmgr.systemConfig']
    }
  }, {
    path: 'vehicleDataAccess',
    component: function component() {
      return import('@/views/vehicleDataAccess/index.vue');
    },
    name: 'vehicleDataAccess',
    meta: {
      title: 'vehicleDataAccess',
      icon: 'subordinate',
      affix: false,
      permissions: ['systemmgr.jtt809905']
    }
  }, {
    path: 'httpProtocolAccess',
    component: function component() {
      return import('@/views/httpProtocolAccess/index.vue');
    },
    name: 'httpProtocolAccess',
    meta: {
      title: 'httpProtocolAccess',
      icon: 'subordinate',
      affix: false,
      permissions: ['systemmgr.http']
    }
  }]
},
// 系统工具
{
  path: '/system-tool',
  component: Layout,
  meta: {
    permissions: ['systemTool'],
    title: 'systemTool',
    icon: 'bg-tool'
  },
  children: [{
    path: 'druid',
    component: function component() {
      return import('@/views/druid/index');
    },
    name: 'druid',
    meta: {
      title: 'druid',
      icon: 'system',
      affix: false,
      permissions: ['druid']
    }
  }, {
    path: 'swagger',
    component: function component() {
      return import('@/views/swagger/index');
    },
    name: 'swagger',
    meta: {
      title: 'swagger',
      icon: 'form',
      affix: false,
      permissions: ['swagger']
    }
  }, {
    path: 'processData',
    component: function component() {
      return import('@/views/processData/index');
    },
    name: 'processData',
    meta: {
      title: 'processData',
      icon: 'system',
      affix: false,
      permissions: ['processdata']
    }
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/version/index');
    },
    name: 'Version',
    meta: {
      title: 'changeLog',
      icon: 'version',
      affix: false,
      permissions: ['version']
    }
  }, {
    path: 'testIndex',
    component: function component() {
      return import('@/views/test/index.vue');
    },
    name: 'testX',
    meta: {
      title: 'test',
      icon: 'version',
      affix: false,
      permissions: ['systemmgr.testmap']
    }
  },
  // {
  //   path: 'supervisor',
  //   component: () => import('@/views/supervisor/index'),
  //   name: 'Supervisor',
  //   meta: { title: 'supervisor', icon: 'data', affix: false, permissions: ['supervisor'] }
  // },
  {
    path: 'honghu',
    component: function component() {
      return import('@/views/honghu/index');
    },
    name: 'Honghu',
    meta: {
      title: 'honghu',
      icon: 'data',
      affix: false,
      permissions: ['supervisor']
    }
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;